<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Pages Overview</h1>
              <div class="pagehead-link"></div>
            </div>
            <p>
              A fixed combination of components, blocks, and views that
              represent a specific function or purpose.
            </p>
          </div>
        </section>

        <section>
          <div class="content-band">
            <div class="flex align-center mb-30">
              <h3>All Pages</h3>
              <div class="p-5 plr-10 mlr-20  rounded-4 white">
                {{ components.length }}
              </div>
            </div>

            <div class="flex-grid flex-wrap gap-10 max-w1000">
              <SmallTile
                v-for="(component, index) in components"
                :key="index"
                :tileName="component"
                route="pages"
              />
            </div>
          </div>
        </section>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import TempLeftNav from "../components/TempLeftNav";
import SmallTile from "../components/SmallTile";
import SubFooter from "../components/SubFooter";
export default {
  name: "PagesOverview",
  data: function() {
    return {
      components: [
        // "search-results",
        "401-error",
        "403-error",
        "404-error",
        "500-error",
        "maintenance",
      ],
    };
  },
  components: {
    TempLeftNav,
    SmallTile,
    SubFooter,
  },
};
</script>
